<template>
    <div class="mt-2">
        <h5 class="text-uppercase mt-0 bg-light p-2">
            Benefits
        </h5>
        <div class="row">
            <div class="col-12 form-group">
                <label for="title">Title</label>
                <input
                    id="title"
                    v-model="benefits.title"
                    type="text"
                    class="form-control"
                    @input="emitBenefits"
                />
            </div>
            <div class="col-12 mb-2">
                <wysiwyg-editor
                    v-model="benefits.content"
                    label="Content"
                    @input="emitBenefits"
                />
            </div>
            <media-chooser
                v-model="benefits.image"
                class="col-12"
                @input="emitBenefits"
            />
        </div>
    </div>
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        WysiwygEditor,
        MediaChooser
    },

    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },

    data() {
        return {
            benefits: {}
        };
    },

    created() {
        this.benefits = { ...this.value };
    },

    methods: {
        emitBenefits() {
            this.$emit('input', this.benefits);
        }
    }
};
</script>
